@import "../global";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap Overrides
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/dist/bootstrap-vue.css';

// Pages
@import 'pages/dashboard';